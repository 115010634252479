<template>
  <div class="container-fuild">
    <div
      class="row"
      style="padding: 1.5rem;"
    >
      <div class="card col-12 col-sm-10 col-xl-9">
        <div class="card-body">
          <div class="mb-3">
            <h3>
              เพิ่ม/แก้ไข สินค้า
            </h3>
          </div>
          <div class="row mb-1">
            <div class="col-12 col-sm-6 col-xl-5 text-center mb-2">
              <div class="mb-1">
                <b-img
                  thumbnail
                  fluid
                  :src="input.pic"
                  style="max-width:209px;width:100%"
                  @error="setAltImg"
                />
              </div>
              <div class="text-left">
                <b-form-file
                  v-model="file"
                  placeholder="เพิ่มรูปสินค้า"
                />
              </div>
            </div>
            <div class="col-12 col-sm-6 col-xl-7 ">
              <validation-observer ref="productRules">
                <table>
                  <thead>
                    <tr>
                      <th style="min-width:85px; width:30%;" />
                      <th style="width:55%;" />
                      <th style="min-width:60px; width:15%;" />

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="pr-1">
                        <p>ชื่อสินค้า</p>
                      </td>
                      <td colspan="2">
                        <validation-provider
                          #default="{ errors }"
                          name="Name"
                          rules="required"
                        >
                          <b-form-input
                            id="h-first-name"
                            ref="name"
                            v-model="input.name"
                            :readonly="statusPage==='เพิ่ม'? true:false"
                            :state="errors.length > 0 ? false:null"
                            autofocus
                          />
                          <small
                            v-if="errors.length > 0"
                            class="text-danger"
                          >กรุณากรอกชื่อสินค้า</small>
                        </validation-provider>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <p>
                          จำนวน
                        </p>
                      </td>
                      <td colspan="2">
                        <b-input-group class="setSpinbutton">
                          <b-input-group-prepend>
                            <b-button
                              variant="outline-secondary"
                              class="py-0"
                              size="sm"
                              style="width: 35px;
                                      border: 1px solid #d8d6de !important;
                                          color: white;
                                        background-color: #EA5354;"
                              :disabled="statusPage==='แก้ไข'? true:false"
                              @click="valueAmountChange(1)"
                            >
                              <b-icon
                                icon="dash"
                                font-scale="1.6"
                                style="margin-left: -8px;"
                              />
                            </b-button>
                          </b-input-group-prepend>

                          <VueNumberFormat
                            v-model="input.amount"
                            :readonly="statusPage==='แก้ไข'? true:false"
                            class="text-center"
                            :class="{ 'border-danger' : checkAmount }"
                            style="padding: 0.438rem 1rem;
                            background-color: #fff;
                            background-clip: padding-box;
                            border: 1px solid #d8d6de;
                                position: relative;
                            flex: 1 1 auto;
                            width: 1%;
                            min-width: 0;"
                          />

                          <!-- <b-form-input
                          v-model="input.amount"
                          type="number"
                          min="0"
                          max="10000"
                          class="border-secondary text-center"
                          style="padding: 0 !important;border: 1px solid #d8d6de !important;"
                          number
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          :readonly="statusPage==='แก้ไข'? true:false"
                        /> -->
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              class="py-0"
                              size="sm"
                              style="width: 35px;
                              border: 1px solid #d8d6de !important;
                                  color: white;
                                background-color: #29C76F;"
                              :disabled="statusPage==='แก้ไข'? true:false"
                              @click="valueAmountChange(-1)"
                            >
                              <b-icon
                                icon="plus"
                                font-scale="1.6"
                                style="margin-left: -8px;"
                              />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>

                      <!-- <b-form-spinbutton
                        id="demo-sb"
                        v-model="input.amount"
                        class="spin-cus"
                        min="1"
                        max="1000000"
                        type="number"
                        :readonly="false"
                      /> -->
                      </td>
                      <td>
                        <p class="ml-1">
                          ชิ้น
                        </p>
                      </td>
                    </tr>
                    <tr />
                    <tr v-if="statusPage!=='แก้ไข'">
                      <td />
                      <td colspan="2"> <div class="d-flex justify-content-center">
                                         <b-button
                                           variant="outline-dark"
                                           class="border-primary"
                                           size="sm"
                                           style="width: 35px;
                                padding: 0px;
                                height: 25px;margin-right: 3px;"
                                           @click="onClickAddAmount(10)"
                                         >10
                                         </b-button>
                                         <b-button
                                           variant="outline-dark"
                                           class="border-primary"
                                           size="sm"
                                           style="width: 35px;
                                padding: 0px;
                                height: 25px;margin-right: 3px;"
                                           @click="onClickAddAmount(50)"
                                         >50
                                         </b-button>
                                         <b-button
                                           variant="outline-dark"
                                           class="border-primary"
                                           size="sm"
                                           style="width: 35px;
                                padding: 0px;
                                height: 25px;"
                                           @click="onClickAddAmount(100)"
                                         >100
                                         </b-button>

                                       </div>
                        <small
                          v-if="checkAmount"
                          class="text-danger"
                        >กรุณากรอกจำนวนสินค้า</small>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <p>
                          ราคา
                        </p>
                      </td>
                      <td colspan="2">
                        <VueNumberFormat
                          v-model="input.price"
                          class="text-right inputFormat"
                          style=""
                          :class="{ 'border-danger' : checkPrice }"
                          :readonly="statusPage==='เพิ่ม'? true:false"
                        />
                        <!-- <b-form-input
                      v-model="input.price"
                      class="text-right"
                      :readonly="statusPage==='เพิ่ม'? true:false"
                      @keyup="numberWithCommas($event)"
                    /> -->

                      </td>
                      <td>
                        <p class="ml-1">
                          บาท
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td colspan="2">
                        <small
                          v-if="checkPrice"
                          class="text-danger"
                        >กรุณากรอกราคาสินค้า</small>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <p>
                          ส่วนลด
                        </p>
                      </td>
                      <td colspan="2">
                        <b-input-group>
                          <b-form-select
                            v-model="input.promotion"
                            :options="itemdiscount"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="primary"
                              class="py-0"
                              size="sm"
                              style="width: 35px;
                              border: 1px solid #d8d6de !important;
                                  color: white;
                                "
                              @click="openAddDiscount"
                            >
                              <feather-icon
                                icon="SettingsIcon"
                                style="margin-left: -4px;"
                              />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </td>

                    </tr>
                    <tr>
                      <td
                        colspan="3"
                        class="text-right"
                      >
                        <div class="d-flex justify-content-end">
                          <p>{{ `${input.price} / ${input.percen} %` }}</p>
                          <p class="ml-1">
                            = {{ (input.price-(input.price/100)*input.percen)|toCurrency }}
                          </p>
                          <p class="ml-1">
                            บาท
                          </p>
                        </div>
                      </td>

                    </tr>
                    <tr>
                      <td class="pr-1">
                        <p>
                          คลังต้น
                        </p>
                      </td>
                      <td colspan="2">
                        <b-form-select
                          v-model="input.warehouseId"
                          :options="warehuse"
                          :disabled="statusPage==='แก้ไข'? true:false"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <p>รายละเอียด</p>
                      </td>
                      <td colspan="2">
                        <b-form-textarea
                          id="textarea-default"
                          v-model="input.detail"
                          placeholder="ระบุรายละเอียด"
                          :readonly="statusPage==='เพิ่ม'? true:false"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </validation-observer>
            </div>
          </div>
          <div class="mb-1 d-flex justify-content-center">
            <b-button
              variant="primary"
              class="mr-1"
              style="    width: 100px;"
              @click="onClickConfirm"
            >
              {{ statusPage }}
            </b-button>
            <b-button
              variant="warning"
              class="mr-1"
              style="    width: 100px;"
              @click="onClickReturnPage()"
            >
              ย้อนกลับ
            </b-button>

          </div>
        </div>

      </div>
    </div>
    <b-modal
      id="modal-center"
      ref="my-modal"
      v-model="IsopenModal"
      hide-footer
      centered
      header-bg-variant="primary"
    >
      <promotionAdd
        @loaderPromotion="loaderPromotion"
        @getPromotion="getPromotion"
      /></b-modal>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { BImg, BButton, BFormInput, BFormSpinbutton, BFormSelect, BFormFile, BFormTextarea, BInputGroupPrepend, BInputGroup, BInputGroupAppend, BIcon, BIconDash, BIconPlus } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import PlaceHolder from '@/assets/images/production/img_default.png'
import promotionAdd from '../promotion/promotion_add.vue'
import data from '@/router/routes/ui-elements'

export default defineComponent({
  name: 'product_add',
  components: {
    BImg,
    BButton,
    BFormInput,
    BFormSpinbutton,
    BFormSelect,
    BFormFile,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroup,
    BInputGroupAppend,
    BIcon,
    BIconDash,
    BIconPlus,
    ValidationProvider,
    ValidationObserver,
    promotionAdd

  },
  computed: {
    statusPage () {
      // eslint-disable-next-line no-nested-ternary
      return this.$route.params.status === undefined ? 'ยืนยัน' : this.$route.params.status === 'edit' ? 'แก้ไข' : 'เพิ่ม'
    },
    textPercen () {

    }

  },
  watch: {
    'input.warehouseId': {
      handler (val) {
        if (this.statusPage === 'ยืนยัน') return
        if (!this.onLoad) {
          this.onLoad = true
          return
        }
        this.getStock()
      }
    },
    datasWareHouse (val) {
      if (this.statusPage === 'ยืนยัน' && val.length > 0) {
        this.input.warehouseId = val[0]._id
      }
    },
    'input.price': {
      handler (val) {
        if (val > 0) {
          this.checkPrice = false
        }
      }
    },
    'input.amount': {
      handler (val) {
        if (val > 0) {
          this.checkAmount = false
        }
      }
    },
    'input.promotion': {
      handler (val) {
        if (val) {
          const data = this.itemdiscount.filter(x => x.value === val)
          // eslint-disable-next-line radix
          this.input.percen = parseInt(data[0].percen)
        } else {
          this.input.percen = 0
        }
      }
    },
    file (e) {
      const newfile = e
      this.input.pic = URL.createObjectURL(newfile)
    }
  },
  methods: {
    getPromotion () {
      this.itemdiscount = [{ value: null, text: 'ไม่มีส่วนลด' }]
      this.$store.dispatch('promotion/getPromotionAll').then(res => {
        res.data.items.forEach(data => {
          this.itemdiscount.push({ value: data._id, text: data.name, percen: data.value.$numberDecimal })
        })
      })
    },
    openAddDiscount () {
      this.IsopenModal = true
    },
    loaderPromotion () {
      this.IsopenModal = false
    },
    onCkickAddtranfer () {},
    onClickAddAmount (val) {
      this.input.amount += val
    },
    valueAmountChange (val) {
      if (this.input.amount >= 0) {
        this.input.amount -= val
        if (this.input.amount <= 0) this.input.amount = 0
      }
    },
    onClickReturnPage () {
      this.$router.go(-1)
    },

    loader () {
      this.loadSetpage()
      this.loadWareHouse()
      this.getPromotion()
    },
    loadSetpage () {
      if (this.statusPage === 'ยืนยัน') {
        this.input = {
          _id: '',
          name: '',
          amount: 0,
          price: 0,
          detail: '',
          pic: '',
          employerId: JSON.parse(localStorage.getItem('userData')).id,
          promotion: null,
          percen: 0
        }
      } else {
        this.$store.dispatch('production/getProduct', { code: this.$route.params.code }).then(result => {
          if (result.data.items.length > 0) {
            const data = result.data.items[0]
            this.input._id = data._id
            this.input.name = data.name
            this.input.price = parseFloat(data.price.$numberDecimal)
            // this.input.amount = parseFloat(data.amount.$numberDecimal)
            this.input.detail = data.detail
            this.input.pic = this.$baseURL + data.pic
            if (data.promotion) {
              this.input.promotion = data.promotion._id
              // eslint-disable-next-line radix
              this.input.percen = parseInt(data.promotion.value.$numberDecimal)
            }
            if (this.$route.params.whs) this.input.warehouseId = this.$route.params.whs
            this.getStock()
          }
        })
      }
    },
    loadWareHouse () {
      this.$store.dispatch('warehouse/getWareHouse', this.input).then(result => {
        this.datasWareHouse = result.data.items
        result.data.items.forEach(data => {
          this.warehuse.push({ value: data._id, text: data.name })
        })
      })
    },
    getStock () {
      this.$store.dispatch('stock/getStock', { productId: this.input._id, warehouseId: this.input.warehouseId }).then(result => {
        if (result.data.items.length > 0) {
          const Stock = result.data.items[0]
          this.input.amount = parseFloat(Stock.amount.$numberDecimal)
          this.input.warehouseId = Stock.warehouseId
        } else {
          this.input.amount = 0
        }
      })
    },
    onClickConfirm () {
      if (this.statusPage === 'ยืนยัน') { this.onClickAddProduc() } else if (this.statusPage === 'เพิ่ม') { this.onClickUpdateproduct() } else this.onClickEdit()
    },
    onClickEdit () {
      this.$refs.productRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('production/editProduct', this.input).then(result => {
            if (result.data.success) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: result.data.message
                }
              })
              this.uploadPic()
              this.$router.push('/product/list')
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: result.data.message
                }
              })
            }
          })
        }
      })
    },
    onClickUpdateproduct () {
      this.$refs.productRules.validate().then(success => {
        if (success) {
          console.log('%%%%%', this.input)
          this.$store.dispatch('production/AddProduct', this.input).then(result => {
            if (result.data.success) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: result.data.message
                }
              })
              if (this.$route.params.whs) this.onClickReturnPage()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: result.data.message
                }
              })
            }
          })
        }
      })
    },
    onClickAddProduc () {
      this.checkForm()
      this.$refs.productRules.validate().then(success => {
        console.log('00000', this.input)

        if (success) {
          if (!this.checkPrice && !this.checkAmount) {
            this.$store.dispatch('production/AddProduct', this.input).then(result => {
              if (result.data.success) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: result.data.message
                  }
                })
                this.input._id = result.data.id
                this.uploadPic()
                this.input = {
                  _id: '',
                  name: '',
                  amount: 0,
                  price: 0,
                  detail: '',
                  pic: '',
                  employerId: '',
                  promotion: null,
                  percen: 0
                }
                this.file = null
                // router.push('/employee/list')
                this.$refs.name.$el.focus()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: result.data.message
                  }
                })
              }
            })
          }
        }
      })
    },
    uploadPic () {
      if (this.file !== null) {
        const formData = new FormData()
        formData.append('product', this.file)
        formData.append('id', this.input._id)
        this.$store.dispatch('upload/uploadProduct', formData).then(success => {
          this.input.id = ''
        })
      }
    },
    setAltImg (e) {
      e.target.src = PlaceHolder
    },
    checkForm () {
      if (this.input.price === 0) {
        this.checkPrice = true
      }

      if (this.input.amount === 0) {
        this.checkAmount = true
      }
    }
  },
  created () {
    this.loader()
  },
  data () {
    return {
      onLoad: false,
      file: null,
      checkPrice: false,
      checkAmount: false,
      IsopenModal: false,
      itemdiscount: [],
      input: {
        _id: '',
        name: '',
        amount: 0,
        price: 0,
        detail: '',
        warehouseId: '',
        pic: '',
        employerId: JSON.parse(localStorage.getItem('userData')).id,
        promotion: null,
        percen: 0
      },
      required,
      warehuse: [],
      datasWareHouse: []
    }
  }
})
</script>
<style scoped>
  p {
    margin-top:11px
}
</style>
<style>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'แนบไฟล์';
}
.inputFormat {
  padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    width: 100%;
}
.inputFormat:active, .inputFormat:focus{
  border: unset;
}
.spin-cus button:last-child {
  background-color: #29C76F !important;
}
.spin-cus button:first-child {
background-color: #EA5354 !important;
}
</style>
